.input {
  margin-top: 1.5rem;
  position: relative;
  font-family: Sen, sans-serif !important;
  label {
    position: absolute;
    font-size: 1rem;
    left: 1.5rem;
    top: 1.125rem;
    z-index: 3;
    color: #2296f3;
    transition: top 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
    pointer-events: none; // disable click events on the label
  }
  input {
    position: relative;
    z-index: 2;
    font-size: 17px;
    color: #000000;
    border: 1.25px solid rgba(0, 0, 0, 0.1);
    border-radius: 6.16998px;
    width: 100%;
    height: 3.5rem;
    padding: 0.25rem 1.125rem 0.25rem 1.5rem;
    outline: none;
    box-shadow: none;
    background: #f4f4f7;
    &:focus {
      background: #f4f4f7;
      border: 1.25px solid rgba(34, 150, 243, 0.7);
    }
    &:not(:placeholder-shown) {
      // background: #f4f4f7;
      border: 1.25px solid rgba(34, 150, 243, 0.9);
    }
    &:focus + label,
    &:not(:placeholder-shown) + label {
      left: 1.5rem;
      top: -0.575rem;
      font-size: 0.8rem;
      background: #f4f4f7;
      padding: 0 0.25rem;
      z-index: 3;
    }
  }
  svg {
    font-size: 1.25rem;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    z-index: 3;
    cursor: pointer;
  }
}
